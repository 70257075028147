/* customStyles.css */
.bg-gray-lines {
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(211, 211, 211, 0.2) 0px,
      rgba(211, 211, 211, 0.2) 1px,
      transparent 1px,
      transparent 5px
    );
  }
  